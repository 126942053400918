import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { parseJwt, useAuth } from "../../../contexts/AuthContext";
import { doLogin, secondStep } from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { CheckBox } from "./CheckBox";
import { CodeField } from "./Codefield";
import { RoundWhiteInput } from "./RoundWhiteInput";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function LoginTab({ setModalOpen, setRegisterOpen }: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const [doisFatores, setDoisFatores] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");

  const [doisFatoresTimeout, setDoisFatoresTimeout] = useState<number>(
    Date.now() + 10000
  );
  
  const timer = (
    <Countdown
      date={new Date(doisFatoresTimeout)}
      daysInHours
      onComplete={() => {
        setDoisFatores(false);
      }}
    />
  );
  const { setAuthUser } = useAuth();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);

  const entrar = () => {
    setLoading(true);
    const params = {
      username: (document.getElementById("email") as HTMLInputElement).value,
      password: (document.getElementById("pass") as HTMLInputElement).value,
    };
    doLogin(params, (response: any) => {
      if (response.status === 200) {
        const token = response.headers.get("Authorization");
        setDoisFatoresTimeout(parseJwt(token).exp * 1000);
        UserToken.email = params.username;
        UserToken.pass = params.password;
        UserToken.auth = token;
        setDoisFatores(true);
      }
      setLoading(false);
    });
  };

  const confirmar = () => {
    setLoading(true);
    const remind = (document.getElementById("lembrar") as HTMLInputElement).checked;
    const params = {
      code:
        (document.getElementById("tokenCode1") as HTMLInputElement).value +
        (document.getElementById("tokenCode2") as HTMLInputElement).value +
        (document.getElementById("tokenCode3") as HTMLInputElement).value +
        (document.getElementById("tokenCode4") as HTMLInputElement).value +
        (document.getElementById("tokenCode5") as HTMLInputElement).value +
        (document.getElementById("tokenCode6") as HTMLInputElement).value,
      remind: remind,
    };

    secondStep(params, (response: any) => {
      if (response.status === 200) {
        setAuthUser({
          token: response.headers.get("Authorization"),
          reminder: response.headers.get("Remind")
        });
        setModalOpen(false);
      }else{
        setTitle("Erro.");
        setMessage("Código inválido.");
        setConfirm("OK");
      }
      setLoading(false);
    });
  };

  return (
    <div
      style={{
        zIndex: "2000",
        position: "absolute",
        top: "0px",
        right: "0px",
        width: "400px",
        height: "400px",
        backgroundColor: "#22CC2288",
        overflow: "hidden",
        borderBottomLeftRadius: "16px",
      }}
      ref={modalContainerRef}
    >
      <div style={{ position: "relative" }}>
        {message !== "" ? (
          <div
            style={{
              position: "fixed",
              top: "calc(50% - 150px)",
              left: "calc(50% - 150px)",
              width: "300px",
              height: "200px",
              backgroundColor: "black",
              borderRadius: "15px",
              borderColor: "green",
              borderStyle: "solid",
              zIndex: "100000",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "24px",
                color: "white",
                fontWeight: "bold",
                paddingTop: "10px",
              }}
            >
              {title}
            </div>
            <div
              style={{
                width: "calc(100% - 20px)",
                height: "90px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                fontSize: "16px",
                color: "white",
              }}
            >
              {message}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button
                style={{
                  padding: "10px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "5px",
                  borderColor: "transparent",
                  borderWidth: "1px",
                  width: "80px",
                }}
                onClick={()=>{
                  setMessage("");
                }}
              >
                {confirm}
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>



      {!doisFatores ? (
        <div style={{ position: "relative" }}>
          <div
            className="blurry"
            style={{
              width: "400px",
              height: "400px",
              borderColor: "transparent",
              position: "absolute",
              zIndex: 0,
              borderBottomLeftRadius: "16px",
            }}
          ></div>
          <div
            style={{
              zIndex: 0,
              position: "absolute",
              width: "100%",
              color: "white",
              paddingTop: "50px",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            <div style={{ paddingBottom: "20px" }}>Acesse Sua Conta</div>
            <form onSubmit={entrar}>
              <RoundWhiteInput
                label="E-mail"
                type="text"
                id="email"
                defaultValue={UserToken.email}
              />
              <RoundWhiteInput
                label="Senha"
                type="password"
                id="pass"
                defaultValue={UserToken.pass}
              />
              <button
                style={{
                  borderRadius: "25px",
                  padding: "10px",
                  width: "280px",
                  backgroundColor: loading ? "gray" : "black",
                  color: loading ? "black" : "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  borderColor: "lightgray",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                disabled={loading}
                onClick={entrar}
              >
                {" "}
                {loading ? "Loading..." : "Entrar"}
              </button>
            </form>
            <hr
              style={{ width: "200px", height: "1px", borderStyle: "none" }}
              color="#CCCCCCAA"
            />
            <div style={{ fontSize: "15px" }}>
              Ainda não tem conta?{" "}
              <button
                style={{
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setModalOpen(false);
                  setRegisterOpen(true);
                }}
              >
                Registre-se
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ position: "relative", maxHeight: "400px", height: "100%" }}
        >
          <div
            className="blurry"
            style={{
              width: "400px",
              height: "400px",
              borderColor: "transparent",
              position: "absolute",
              zIndex: 1,
              top: 0,
              borderBottomLeftRadius: "16px",
            }}
          ></div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              maxHeight: "360px",
              justifyContent: "center",
              alignContent: "flex-start",
              height: "100%",
              color: "white",
              padding: "20px",
              gap: "8px",
              zIndex: 999,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              Autenticação de Dois Fatores
            </div>
            <div style={{ fontSize: "14px" }}>
              Para poder acessar o sistema confirme abaixo o código enviado para
              seu e-mail cadastrado:
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginInline: "auto",
                justifyContent: "center",
                paddingBlock: "16px",
                gap: "5px",
              }}
            >
              <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                Código de Autenticação
              </div>
              <CodeField id="tokenCode" />
              <div style={{ fontSize: "12px" }}>
                {"Tempo limite para validação do código: "}
                <span style={{ textDecoration: "underline" }}> {timer}</span>
              </div>
            </div>
            <div style={{ marginTop: "8px" }}>
              <CheckBox
                label="Lembrar deste dispositivo por 14 dias"
                link=""
                linkAction={() => {}}
                id="lembrar"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginInline: "auto",
                justifyContent: "center",
                paddingTop: "8px",
                gap: "8px",
              }}
            >
              <button
                style={{
                  borderRadius: "25px",
                  padding: "10px",

                  width: "280px",
                  backgroundColor: loading ? "gray" : "black",
                  color: loading ? "black" : "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  borderColor: "lightgray",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
                disabled={loading}
                onClick={confirmar}
              >
                {loading ? "Loading..." : "Confirmar código"}
              </button>
              <button
                key="c-2"
                className={`button hovered`}
                onClick={() => {
                  setDoisFatores(false);
                }}
              >
                {"Voltar"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
