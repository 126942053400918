import { pagContato, pagFaq, pagSobre, pagTermos } from "./MainPage";
import pj from "../../../package.json";



export function Rodape(){

    return <div
    style={{
      color: "white",
      justifyContent: "space-around",
      display: "flex",
      paddingTop: "100px",
      fontSize: "20px",
    }}
  >
    <div style={{ width: "10%" }}></div>
    <div style={{ width: "20%", minWidth:"250px" }}>
      <img src="numberwinlogo.png" />
      <div
        style={{
          fontSize: "14px",
          paddingTop: "20px",
          color: "darkgray",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>
      <div style={{ fontSize: "12px", paddingTop: "8px" }}>
        v.{pj.version} {process.env.REACT_APP_ENV}
      </div>
    </div>
    <div style={{ width: "20%" }}>
      <div style={{ paddingBottom: "20px" }}>Fale Conosco</div>
      <div
        style={{
          fontSize: "14px",
          paddingTop: "20px",
          color: "darkgray",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>
    </div>
    <div style={{ width: "20%" }}>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagSobre;
        }}
      >
        Sobre a NumberWin
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagFaq;
        }}
      >
        Perguntas frequentes
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagTermos;
        }}
      >
        Termos de Uso e Política de Privacidade
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagContato;
        }}
      >
        Reporte um problema
      </div>
    </div>

    <div style={{ width: "10%", minWidth:"150px" }}>
      <img style={{ paddingLeft: "20px" }} src="icon_facebook.png" />
      <img style={{ paddingLeft: "20px" }} src="icon_instagram.png" />
      <img style={{ paddingLeft: "20px" }} src="icon_youtube.png" />
    </div>
  </div>
}