import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getProfileImage } from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { ButtonActionProp, DropdownMenuButton } from "./DropdownMenuButton";
import { MenuButton } from "./MenuButton";
import { DropdownMenuButton_M } from "./DropdownMenuButton_M";
import { CloseOutside } from "./Util";

export interface TopButtonsProps {
  setLoginTabActive: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterTabActive: React.Dispatch<React.SetStateAction<boolean>>;
  activePage: number;
}

export function TopButtons_M({
  setLoginTabActive,
  setRegisterTabActive,
  activePage,
}: TopButtonsProps) {
  const { hasPermission } = useAuth();
  const logged = hasPermission("ROLE_CLIENT");
  const [imgProfile, setImgProfile] = useState<any>("");
  const [showing, setShowing] = useState<boolean>(false);
  
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  CloseOutside(modalContainerRef, setShowing);

  useEffect(() => {
    if (logged) {
      getProfileImage((file: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        // here we tell the reader what to do when it's done reading...
        reader.onloadend = (readerEvent) => {
          if (readerEvent) {
            var content = readerEvent.target?.result; // this is the content!
            if (content) {
              setImgProfile(content);
            }
          }
        };
      });
    }
  }, []);

  const sorteioButtons: ButtonActionProp[] = [
    {
      label: "Sorteios",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          1;
      },
    },
  ];
  const campanhaButtons: ButtonActionProp[] = [
    {
      label: "Campanha de Celular",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          9 +
          "&tipo=Celular&background=campanhacelular.png";
      },
    },
    {
      label: "Campanha de CPF",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          9 +
          "&tipo=CPF&background=campanhacpf.png";
      },
    },
    {
      label: "Campanha de CNPJ",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          9 +
          "&tipo=CNPJ&background=campanhacnpj.png";
      },
    },
    {
      label: "Campanha de Insc. Mobiliária",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          9 +
          "&tipo=Insc.Mob.&background=campanhaimob.png";
      },
    },
    {
      label: "Campanha de Placas de Carro",
      action: () => {
        window.location.href =
          window.location.href.substring(0, window.location.href.indexOf("?")) +
          "?pag=" +
          9 +
          "&tipo=Carro&background=campanhacarro.png";
      },
    },
  ];

  return (
    <div
      id="header-menu"
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        flexWrap:"wrap",
        paddingTop: "5px",
        height: "60px",
      }}
    >

      <img src="numberwinlogo.png" style={{ width: "216px", height: "50px" }} onClick={()=>{setShowing(!showing)}}/>


      <div></div>

      <div style={{position:"relative"}}>
        {showing&&
        <div style={{position:"fixed", top:0,right:0, 
          backgroundColor: "green",
          borderRadius: "18px",
          borderColor: "green",
          borderStyle: "solid",}}
          ref={modalContainerRef}
          >
          

          <MenuButton
        label="Início"
        action={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            0;
        }}
        inverted={activePage === 0}
      />

<DropdownMenuButton_M title="Sorteios" buttons={sorteioButtons} inverted={activePage===1}/>

<DropdownMenuButton_M title="Campanhas" buttons={campanhaButtons}  inverted={activePage===9}/>

      {!logged ? (
        <div style={{  }}>
          <MenuButton
            label="Entrar"
            action={() => {
              setLoginTabActive(true);
            }}
            
          />
          <MenuButton
            label="Registrar-se"
            action={() => {
              setRegisterTabActive(true);
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            paddingTop: "0px",
          }}
        >
          {Boolean(imgProfile) && imgProfile !== "" ? (
            <img
              src={imgProfile}
              style={{
                display: "flex",
                width: "44px",
                height: "44px",
                borderRadius: "22px",
                borderStyle: "solid",
                borderColor: "green",
              }}
            />
          ) : (
            <img
              src={"iconUser.png"}
              style={{
                display: "flex",
                width: "44px",
                height: "44px",
                borderRadius: "22px",
                borderStyle: "solid",
                borderColor: "green",
                backgroundColor: "gray",
              }}
            />
          )}

          <div
            style={{
              color: "white",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                paddingTop: "3px",
              }}
            >
              <b>{UserToken.login}</b>
            </div>
            <div
              style={{
                fontSize: "14px",
              }}
            >
              {UserToken.email}
            </div>
          </div>
          <img
            src="lapis.png"
            width={"30px"}
            height={"30px"}
            style={{ paddingTop: "10px" }}
            onClick={() => {
              window.location.href =
                window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                ) +
                "?pag=" +
                3;
            }}
          />
        </div>
      )}


        </div>
        }
      </div>
      <div><img src="menu_M.png" style={{ width: "30px", height: "30px", padding:"10px", paddingRight:"30px" }} onClick={()=>{setShowing(!showing)}}/></div>
    </div>
  );
}
